$nice_color-main_color: #2dc3b7;
$nice_color-main_lighter_color: #2dc3b750;
$nice_color-disabled_color: #2dc3b733;
$nice_color-secondary_color: #0263cc;
$nice_color-secondary_disabled_color: #0263cc33;
$nice_color-gray_dark_ultra: #000000;
$nice_color-gray_dark: #919191;
$nice_color-gray_dark_semi: #ababab;
$nice_color-gray: #c1c1c1;
$nice_color-gray_light_semi: #dddddd;
$nice_color-gray_light: #e9e9e9;
$nice_color-gray_light_ultra-2: #f7f7f7;
$nice_color-gray_light_ultra: #ffffff;
$nice_color-cyan: #2dc3b7;
$nice_color-cyan_light: rgba(45, 195, 183, .2);
$nice_color-cyan_lighter: rgba(45, 195, 183, .3);
$nice_color-cyan_light_ultra: #e0f6f4;
$nice_color-green: #21cf52;
$nice_color-green_light_ultra: rgba(33, 207, 82, .2);
$nice_color-yellow_dark_semi: #eac000;
$nice_color-yellow: #ffd100;
$nice_color-yellow_light_ultra: rgba(234, 192, 0, .2);
$nice_color-red: #ff6236;
$nice_color-red_light_ultra: #ffe0d7;
$nice_color-purple: #7c5ecf;
$nice_color-purple_light_ultra: #e5dff5;
$nice_color-blue: #0263cc;
$nice_color-blue__disabled: #0263cc33;
$nice_color-blue_light_ultra: #cce0f5;
$nice_color-navy: #1a2e46;
$nice_color-navy_light_ultra: #d1d5da;
$nice_color-black: #000000;
$nice_color-default: #232323;
$nice_color-gray-dark: #919191;
$nice_color-graydark: #919191;
$nice_color-darkgray: #919191;
$nice_color-gray-semidark: #ababab;
$nice_color-graysemidark: #ababab;
$nice_color-semidarkgray: #ababab;
$nice_color-black__disabled: #b2b2b2;
$nice_color-grayest: #dddddd;
$nice_color-graydark__disabled: #dedede;
$nice_color-darkgray__disabled: #dedede;
$nice_color-graylight: #e1e1e1;
$nice_color-lightgray: #e1e1e1;
$nice_color-grayest__disabled: #e1e1e1;
$nice_color-semidarkgray__disabled: #e1e1e1;
$nice_color-graysemidark__disabled: #e1e1e1;
$nice_color-gray-light: #e9e9e9;
$nice_color-gray__disabled: #ececec;
$nice_color-graylightest: #f7f7f7;
$nice_color-white: #ffffff;
$nice_color-white__disabled: #ffffff;
$nice_color-activebutton: #cde0f5;
$nice_color-bluelight: #9ac1ec;
$nice_color-blue_dark: #1a2e46;
$nice_color-blue-gray: #d1d5da;
$nice_color-proposed: #1a2e46;
$nice_color-blue_dark__disabled: #424f5e;
$nice_color-booked: #0263cc;
$nice_color-button: #0263cc;
$nice_color-greenligth: #c0ede9;
$nice_color-ar_green: #2dc3b7;
$nice_color-ar_green__disabled: #c0ede9;
$nice_color-green-ultra-light: #e0f6f4;
$nice_color-red-light: #ffe0d7;
$nice_color-red__disabled: #ffc4b4;
$nice_color-purple-light: #e5dff5;
$nice_color-purple__disabled: #e5dff5;
@import '@/assets/styles/mixin.sass';





















































































































































































































































































































































































































































.creative-preview {
	display: grid;
	grid-template-columns: 160px auto;
	grid-template-areas: "preview top" "preview bottom";

	background: $nice_color-gray_light_ultra-2;
	border-radius: 10px;
	min-height: 125px;
	margin-bottom: 10px;
	padding: 20px;
	column-gap: 30px;
	row-gap: 35px;

	box-sizing: border-box;
	min-height: 125px; }


.preview {
	position: relative;
	z-index: 0;
	grid-area: preview;
	align-self: stretch;
	justify-self: stretch;
	overflow: hidden;
	border-radius: 10px;

	min-height: 90px;

	display: flex;
	align-items: center;
	justify-content: center;
	background: $nice_color-gray; }

.preview-icon {
	display: block;
	position: absolute;
	height: 60px;
	width: 100%;
	opacity: .3;
	top: 50%;
	left: 0%;
	transform: translate3d(0, -50%, 0);
	--ni-icon-sign: currentColor; }

.progress-bar {
	position: absolute;
	z-index: 1;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	&::v-deep > .ni_progress_bar--bar {
		height: 100%;

		&::before {
			top: 0;
			height: 100%; }

		&::after {
			top: 0;
			height: 100%;
			opacity: .7; } } }

.video-preview {
	position: absolute;
	display: block;
	height: 100%;
	width: 100%;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	object-fit: cover; }

.top,
.bottom {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center; }

.top {
	grid-area: top;
	align-self: start;
	padding-top: 5px; }

.bottom {
	grid-area: bottom;
	align-self: end;
 }	// padding-bottom: 5px



.title {
	align-self: flex-start;
	font-size: $fsz__normal;
	font-weight: normal;
	margin: 0; }

.audits-container {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
 }	// align-items: center

.status {
	display: flex;
	color: $nice_color-darkgray;
	font-size: $fsz__smaddle;
	align-items: center; }

.status,
.status-list {
	margin-left: auto;
	margin-bottom: 10px;

	& > :not(:first-child) {
		margin-left: 10px; } }

.details {
	grid-area: details;
	align-self: end;

	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center; }


.detail-label {
	margin-top: -8px; }

.detail {
	display: flex;
	flex-direction: column;

	&:not(:first-child) {
		margin-left: 35px; }

	& > .value {
		margin: 0;
		line-height: 30px; } }

.value.placeholder {
	color: var(--text_2_color); }

.actions {
	display: flex;
	align-items: center;
	margin-left: auto;

	& > :not(:first-child) {
		margin-left: 30px; } }
